<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto">
          <h1>App Settings</h1>
        </v-col>
      </v-row>
      <div
        v-if="hasPermission('edit-sensitive-data-password', $route.params.id)"
      >
        <v-alert type="info" outlined prominent>
          <strong>A strong password is required.</strong> Enter 8-100
          characters. Do not include common words or names. Combine uppercase
          letters, lowercase letters, numbers and symbols.
        </v-alert>
        <v-card outlined>
          <v-card-title>Sensitive Data Password</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <div class="mb-8">
              <p>
                This password is used to encrypt any data that is deemed
                sensitive. Only users with the correct permission to view the
                sensitive data will be able to enter the password.
              </p>
              <p>
                If you can see this section, it means you have been assigned the
                permission to be able to view sensitive data. You will still
                need to enter the password to decrypt the data.
              </p>
            </div>
            <v-form
              @submit.prevent="savePassword"
              method="post"
              ref="passwordForm"
              id="password-form"
            >
              <v-alert
                v-model="passwordForm.error"
                type="error"
                prominent
                class="mb-8"
              >
                <strong>Error!</strong> Something went wrong when attempting to
                change your password. Please check that your current password is
                correct and try again. If this problem persists, please contact
                support.
              </v-alert>
              <v-text-field
                label="Current Password"
                v-if="appHasPassword"
                v-model="passwordForm.fields.current"
                outlined
                :type="passwordForm.show.current ? 'text' : 'password'"
                :append-icon="
                  passwordForm.show.current ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  passwordForm.show.current = !passwordForm.show.current
                "
                :rules="[passwordForm.rules.required]"
              ></v-text-field>
              <v-text-field
                label="New Password"
                v-model="passwordForm.fields.new"
                outlined
                counter="100"
                :type="passwordForm.show.new ? 'text' : 'password'"
                :append-icon="passwordForm.show.new ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="passwordForm.show.new = !passwordForm.show.new"
                :rules="[
                  passwordForm.rules.required,
                  passwordForm.rules.password,
                ]"
              ></v-text-field>
              <v-text-field
                label="Confirm New Password"
                v-model="passwordForm.fields.newConfirmed"
                outlined
                counter="100"
                :type="passwordForm.show.newConfirmed ? 'text' : 'password'"
                :append-icon="
                  passwordForm.show.newConfirmed ? 'mdi-eye' : 'mdi-eye-off'
                "
                @click:append="
                  passwordForm.show.newConfirmed = !passwordForm.show
                    .newConfirmed
                "
                :rules="[
                  passwordForm.rules.required,
                  passwordForm.rules.password,
                ]"
              ></v-text-field>
              <div>
                <v-btn
                  depressed
                  color="accent"
                  type="submit"
                  :loading="passwordForm.loading"
                  form="password-form"
                  >Save Password</v-btn
                >
              </div>
            </v-form>
          </v-card-text>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      passwordForm: {
        show: {
          current: false,
          new: false,
          newConfirmed: false,
        },
        fields: {
          current: null,
          new: null,
          newConfirmed: null,
        },
        error: false,
        rules: {
          required: value => !!value || "Field is Required.",
          password: value => {
            const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,100})/;
            return (
              pattern.test(value) ||
              "Min. 8 characters with at least one capital letter, a number and a special character."
            );
          },
          match: value =>
            value === this.passwordForm.new || "Passwords must match.",
        },
        loading: false,
      },
    };
  },

  computed: {
    appHasPassword() {
      return this.$store.getters["appSettings/appHasPassword"];
    },
  },

  methods: {
    savePassword() {
      if (!this.$refs.passwordForm.validate()) {
        return;
      }

      const current_password = this.passwordForm.fields.current;
      const password = this.passwordForm.fields.new;
      const password_confirmation = this.passwordForm.fields.newConfirmed;

      this.loading = true;
      this.passwordForm.error = false;

      this.$store
        .dispatch("appSettings/saveAppPassword", {
          appId: this.$route.params.id,
          fields: {
            current_password,
            password,
            password_confirmation,
          },
        })
        .then(() => {
          this.passwordForm.fields.current = null;
          this.passwordForm.fields.new = null;
          this.passwordForm.fields.newConfirmed = null;
          this.loading = false;
          this.$refs.passwordForm.resetValidation();
          this.$store.commit("showGlobalSnackbar", {
            open: true,
            text: "Sensitive Data Password changed Successfully!",
          });
        })
        .catch(() => {
          this.loading = false;
          this.passwordForm.error = true;
        });
    },
  },
};
</script>
